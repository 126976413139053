export const Training = () => {
    const datasetInfo = {
        name: 'MNIST',
        description: 'MNIST is a set of handwritten digits',
        owner: {
            name: 'Harshal Sheth',
        },
        created: '9 days ago',
        size: '150 MB',
        samples: '70,000',
        about: (
            <>
                The MNIST database (Modified National Institute of Standards and
                Technology database) is a large database of handwritten digits
                that is commonly used for training various image processing
                systems. The database is also widely used for training and
                testing in the field of machine learning. It was created by
                "re-mixing" the samples from NIST's original datasets. The
                creators felt that since NIST's training dataset was taken from
                American Census Bureau employees, while the testing dataset was
                taken from American high school students, it was not well-suited
                for machine learning experiments. Furthermore, the black and
                white images from NIST were normalized to fit into a 28x28 pixel
                bounding box and anti-aliased, which introduced grayscale
                levels.
                <br />
                <br />
                The MNIST database contains 60,000 training images and 10,000
                testing images. Half of the training set and half of the test
                set were taken from NIST's training dataset, while the other
                half of the training set and the other half of the test set were
                taken from NIST's testing dataset. The original creators of the
                database keep a list of some of the methods tested on it. In
                their original paper, they use a support-vector machine to get
                an error rate of 0.8%.
            </>
        ),
    }
    return (
        <>
            <section className="w-full py-8">
                <div className="max-w-screen-lg mx-auto">
                    <h3 className="text-sm text-gray-400">Dataset</h3>
                    <h1 className="text-4xl font-semibold">
                        {datasetInfo.name}
                    </h1>
                    <h2 className="mt-2 text-gray-600">
                        {datasetInfo.description}
                    </h2>
                    <div className="mt-4 text-gray-300">
                        <span className="font-semibold text-black">
                            {datasetInfo.owner.name}{' '}
                        </span>
                        {' • '}
                        <span className="font-semibold text-black">
                            {datasetInfo.created}
                        </span>
                    </div>
                </div>
            </section>

            <section className="border-b ">
                <div className="flex max-w-screen-lg mx-auto">
                    <div className="py-1 mr-8 border-b-2 border-black">
                        Overview
                    </div>
                    <div className="py-1 mr-8">Schema</div>
                    <div className="py-1 mr-8">Version history</div>
                </div>
            </section>
            <section className="mt-12">
                <div className="flex max-w-screen-lg mx-auto">
                    <div className="w-2/3 text-slate-600">
                        {datasetInfo.about}
                    </div>
                    <div className="w-1/3">
                        <div className="flex flex-col pl-12">
                            <div>
                                <h1 className="text-lg font-bold">Size</h1>
                                <h2>{datasetInfo.size}</h2>
                            </div>
                            <div className="mt-8">
                                <h1 className="text-lg font-bold">Samples</h1>
                                <h2>{datasetInfo.samples}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
