import { Route, Switch } from 'react-router-dom'
import { DatasetsTable } from './Dashboard/DatasetsTable'
import { DashboardNavbar } from '../components/DashboardNavbar'
import { Project } from './Dashboard/Project'
import { ProjectsTable } from './Dashboard/ProjectsTable'
import { Dataset } from './Dashboard/Dataset'
import { Training } from './Dashboard/Training'
import { TrainingsTable } from './Dashboard/TrainingsTable'
import { NotFound } from './NotFound'

import { Toaster } from 'react-hot-toast'

const OwnedProjects = () => {
    return <ProjectsTable onlyOwned />
}

const OwnedDatasets = () => {
    return <DatasetsTable onlyOwned />
}

export const Dashboard = () => {
    return (
        <DashboardNavbar>
            <Switch>
                <Route
                    exact
                    path="/app/projects/all"
                    component={ProjectsTable}
                />
                <Route
                    exact
                    path="/app/projects/owned"
                    component={OwnedProjects}
                />
                <Route
                    exact
                    path="/app/project/:projectId"
                    component={Project}
                />
                <Route
                    exact
                    path="/app/datasets/all"
                    component={DatasetsTable}
                />
                <Route
                    exact
                    path="/app/datasets/owned"
                    component={OwnedDatasets}
                />
                <Route
                    exact
                    path="/app/dataset/:datasetId"
                    component={Dataset}
                />
                <Route exact path="/app/trainings" component={TrainingsTable} />
                <Route
                    exact
                    path="/app/training/:trainingId"
                    component={Training}
                />
                <Route path="/" component={NotFound} />
            </Switch>
            <Toaster />
        </DashboardNavbar>
    )
}
