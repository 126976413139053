import { NavLink } from 'react-router-dom'

export const Home = () => {
    return (
        <section className="relative h-screen bg-gradient-to-tr from-green-50 to-purple-100">
            <div className="max-w-6xl px-4 mx-auto sm:px-6">
                {/* Hero content */}
                <div className="pt-32 pb-12 md:pt-64 md:pb-20">
                    {/* Section header */}
                    <div className="pb-12 text-center md:pb-16">
                        <h1
                            className="mt-4 text-5xl font-extrabold tracking-tighter md:text-6xl leading-tighter"
                            data-aos="zoom-y-out"
                        >
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-teal-400">
                                Federated learning
                            </span>{' '}
                            made easy
                        </h1>
                        <div className="max-w-3xl mx-auto mt-8">
                            <p
                                className="mb-8 text-xl text-gray-600"
                                data-aos="zoom-y-out"
                                data-aos-delay="150"
                            >
                                Create and train ML models securely.
                            </p>
                            <div
                                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                                data-aos="zoom-y-out"
                                data-aos-delay="300"
                            >
                                <div>
                                    <NavLink
                                        className="w-full px-6 py-3 mb-4 text-white bg-blue-600 rounded btn hover:bg-blue-700 sm:w-auto sm:mb-0"
                                        to="/login"
                                    >
                                        Log in
                                    </NavLink>
                                </div>
                                <div>
                                    <NavLink
                                        className="w-full px-6 py-3 text-white bg-gray-900 rounded btn hover:bg-gray-800 sm:w-auto sm:ml-4"
                                        to="/about"
                                    >
                                        Learn more
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
