import { useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MorphicInstance } from '../../config'
import { ProjectModel } from '../../generated'
import Empty from '../../illustrations/empty.svg'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { formatDate } from '../../utils/dates'

export const ProjectsTable = ({ onlyOwned }: { onlyOwned: boolean }) => {
    // Table state.
    const [projects, setProjects] = useState<ProjectModel[] | null>(null)
    useEffect(() => {
        if (onlyOwned) {
            MorphicInstance.default.listMyProjects().then(setProjects)
        } else {
            MorphicInstance.default.listAllProjects().then(setProjects)
        }
    }, [onlyOwned, setProjects])

    // Modal state management.
    const [isOpen, setIsOpen] = useState(false)

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [shape, setShape] = useState('')

    const [selectedFile, setSelectedFile] = useState<Blob | null>(null)
    const [isFilePicked, setIsFilePicked] = useState(false)

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(event.target.files && event.target.files[0])
        setIsFilePicked(true)
    }

    const handleSubmit = () => {
        if (selectedFile) {
            MorphicInstance.default
                .createProject({
                    name,
                    description,
                    shape,
                    torch_model: selectedFile,
                })
                .then(() => {
                    setIsOpen(false)
                    // reload projects
                    setProjects(null)
                    MorphicInstance.default.listAllProjects().then(setProjects)
                    toast.success('Project created!')
                })
                .catch((err) => {
                    toast.error(err.message)
                })
        }
    }

    return (
        <>
            <Transition
                show={isOpen}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Dialog
                    onClose={() => setIsOpen(false)}
                    className="fixed inset-0 z-10 overflow-y-auto"
                >
                    <div className="flex items-center justify-center min-h-screen">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                        <div className="relative max-w-screen-md p-8 mx-auto bg-white rounded-xl">
                            <Dialog.Title className="text-lg">
                                Create a project
                            </Dialog.Title>

                            <div>
                                <input
                                    type="text"
                                    className="px-3 py-1.5 mt-4 border rounded-md w-96"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <div>
                                <input
                                    type="text"
                                    className="px-3 py-1.5 mt-4 border rounded-md w-96"
                                    placeholder="Shape"
                                    value={shape}
                                    onChange={(e) => setShape(e.target.value)}
                                />
                            </div>

                            <div>
                                <textarea
                                    className="px-3 py-1.5 mt-4 border rounded-md w-96"
                                    rows={8}
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                />
                            </div>

                            <div className="mt-4">
                                Upload PyTorch model definition (.pt)
                                <div>
                                    <input
                                        type="file"
                                        name="file"
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>

                            <button
                                onClick={handleSubmit}
                                className="px-4 py-2 mx-auto mt-8 mb-4 text-sm font-semibold text-white bg-blue-500 rounded-sm shadow-sm"
                            >
                                Create project
                            </button>

                            {/* ... */}
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <div className="container max-w-screen-lg px-4 mx-auto sm:px-8">
                <div className="py-8">
                    <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                        <h1 className="mb-2 text-2xl font-semibold">
                            {onlyOwned ? 'My Projects' : 'All Projects'}
                        </h1>
                        <p className="mb-4 text-sm text-slate-500">
                            Projects looking for training data.
                        </p>
                        <button
                            onClick={() => {
                                setIsOpen(true)
                                setName('')
                                setDescription('')
                            }}
                            className="px-4 py-2 mx-auto mb-4 text-sm font-semibold text-white bg-blue-500 rounded-sm shadow-sm"
                        >
                            Create a project
                        </button>
                        <div className="inline-block min-w-full overflow-hidden border rounded-lg">
                            <table className="min-w-full leading-normal table-auto">
                                <thead>
                                    <tr>
                                        <td className="px-5 py-5 bg-gray-100">
                                            Name
                                        </td>
                                        <td className="px-5 py-5 bg-gray-100">
                                            Description
                                        </td>
                                        <td className="px-5 py-5 bg-gray-100">
                                            Owner
                                        </td>
                                        <td className="px-5 py-5 bg-gray-100">
                                            Created
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projects ? (
                                        projects.length > 0 ? (
                                            projects.map((project) => (
                                                <tr key={project.id}>
                                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                                        <div className="flex items-center">
                                                            <p className="text-gray-900 whitespace-no-wrap">
                                                                <Link
                                                                    to={`/app/project/${project.id}`}
                                                                    className="text-blue-500 underline whitespace-no-wrap"
                                                                >
                                                                    {
                                                                        project.name
                                                                    }
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                                        <p className="text-gray-600 whitespace-no-wrap">
                                                            {
                                                                project.description
                                                            }
                                                        </p>
                                                    </td>
                                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                                        <p className="text-gray-900 whitespace-no-wrap">
                                                            {project.owner.name}
                                                        </p>
                                                    </td>
                                                    <td className="px-5 py-5 text-sm whitespace-no-wrap bg-white border-b border-gray-200">
                                                        {formatDate(
                                                            project.created
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="flex flex-col items-center justify-center w-full py-24">
                                                        <img
                                                            className="w-1/4"
                                                            src={Empty}
                                                        />
                                                        <div className="mt-12 text-lg">
                                                            No projects found!
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    ) : (
                                        <tr>
                                            <td>
                                                <div>Loading projects...</div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
