import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { MorphicInstance } from '../../config'
import { ProjectWithEligibleDatasets } from '../../generated'
import { formatDate } from '../../utils/dates'

export const Project = () => {
    const history = useHistory()

    const { projectId: projectIdString } = useParams<{ projectId: string }>()
    const projectId = parseInt(projectIdString)

    const [project, setProjectInfo] =
        useState<ProjectWithEligibleDatasets | null>(null)

    useEffect(() => {
        MorphicInstance.default.projectInfo(projectId).then(setProjectInfo)
    }, [projectId, setProjectInfo])

    const [selectedDatasets, setSelectedDatasets] = useState<Set<number>>(
        new Set()
    )

    const addSelectedDataset = (datasetId: number) => {
        setSelectedDatasets(new Set([...selectedDatasets, datasetId]))
    }

    const removeSelectedDataset = (datasetId: number) => {
        setSelectedDatasets(
            new Set([...selectedDatasets].filter((id) => id !== datasetId))
        )
    }

    const toggleSelectedDataset = (datasetId: number) => {
        if (selectedDatasets.has(datasetId)) {
            removeSelectedDataset(datasetId)
        } else {
            addSelectedDataset(datasetId)
        }
    }

    const handleStartTraining = () => {
        MorphicInstance.default
            .createSession({
                project: projectId,
                datasets: [...selectedDatasets],
            })
            .then((session) => {
                history.push(`/app/training/${session.id}`)
            })
    }

    if (!project) {
        return <div>Loading...</div>
    }

    return (
        <>
            <section className="w-full py-8">
                <div className="max-w-screen-lg mx-auto">
                    <h3 className="text-sm text-gray-400">Project</h3>
                    <h1 className="text-4xl font-semibold">{project.name}</h1>
                    <div className="mt-2">
                        ID for training:{' '}
                        <span className="px-2 py-1 ml-1 text-sm border border-gray-300 rounded-md">
                            {project.id}
                        </span>
                    </div>
                    <div className="mt-4 text-gray-300">
                        <span className="font-semibold text-black">
                            {project.owner.name}{' '}
                        </span>
                        {' • '}
                        <span className="font-semibold text-black">
                            {formatDate(project.created)}
                        </span>
                    </div>
                </div>
            </section>

            <section className="border-b ">
                <div className="flex max-w-screen-lg mx-auto">
                    <div className="py-1 mr-8 border-b-2 border-black">
                        Overview
                    </div>
                    {/* <div className="py-1 mr-8">Required Format(s)</div> */}
                    {/* <div className="py-1 mr-8">Training progress</div> */}
                </div>
            </section>
            <section className="mt-12">
                <div className="flex max-w-screen-lg mx-auto">
                    <div className="w-2/3 text-slate-600">
                        {project.description}
                    </div>
                    <div className="w-1/3">
                        <div className="flex flex-col pl-12">
                            <div className="mt-2">
                                <h1 className="text-lg font-bold">Shape</h1>
                                <h2>{project.shape}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-screen-lg mx-auto mt-8 ">
                    {project.eligible_datasets.length ? (
                        <>
                            <div className="flex-col overflow-hidden border rounded-xl">
                                {project.eligible_datasets.map((dataset) => {
                                    const selected = selectedDatasets.has(
                                        dataset.id
                                    )
                                    return (
                                        <button
                                            className={`flex items-center w-full p-4  ${
                                                selected
                                                    ? 'bg-blue-100'
                                                    : 'hover:bg-blue-50'
                                            }`}
                                            onClick={() =>
                                                toggleSelectedDataset(
                                                    dataset.id
                                                )
                                            }
                                        >
                                            <div className="w-1/4 font-semibold text-left">
                                                <input
                                                    type="checkbox"
                                                    checked={selected}
                                                    className="mr-4"
                                                />
                                                {dataset.name}
                                            </div>
                                            <div className="w-1/2 text-left text-gray-500">
                                                {dataset.description}
                                            </div>
                                            <div className="w-1/4 text-left">
                                                {dataset.owner.name}
                                            </div>
                                        </button>
                                    )
                                })}
                            </div>
                            <div className="flex items-center w-full mt-8 mb-16">
                                <button
                                    className="px-4 py-2 mx-auto text-sm font-semibold text-white bg-blue-500 rounded-sm shadow-sm disabled:bg-blue-300"
                                    disabled={selectedDatasets.size == 0}
                                    onClick={handleStartTraining}
                                >
                                    Start Training
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="flex items-center w-full mt-8 mb-16">
                            No datasets with a matching shape. You need to wait
                            for some datasets to be added before you can run
                            training.
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}
