import {
    LineChart,
    Line,
    CartesianGrid,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'

export const transformPayloadToLineChartData = (
    payload: TrainingMetricsPayload
) => {
    // assumes all arrays are of the same length
    const metricNames = Object.keys(payload)
    const length = payload[metricNames[0]].length
    const ret = []
    for (let i = 0; i < length; i++) {
        const temp: ChartDataPoint = {}
        for (const metric in payload) {
            temp[metric] = payload[metric][i]
        }
        ret.push(temp)
    }
    return ret
}

export type TrainingMetricsPayload = {
    [metric: string]: number[]
}
export type ChartDataPoint = { [metric: string]: number }

export type LineChartData = ChartDataPoint[]

const sampleTrainingMetrics = {
    accuracy: [0.2, 0.4, 0.6, 0.8, 0.9, 0.95, 0.9],
    loss: [0.8, 0.5, 0.2, 0.1, 0.05, 0.075, 0.05],
}
const sampleRawPayload = `{"accuracy":[0.2,0.4,0.6,0.8,0.9,0.95,0.9],"loss":[0.8,0.5,0.2,0.1,0.05,0.075,0.05]}`
const samplePayload = JSON.parse(sampleRawPayload)
const transformedSamplePayload = transformPayloadToLineChartData(samplePayload)

export const SampleLineChart = () => (
    <LineChart
        width={730}
        height={250}
        data={transformedSamplePayload}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="accuracy" stroke="#8884d8" />
        <Line type="monotone" dataKey="loss" stroke="#82ca9d" />
    </LineChart>
)

export const TrainingLineChart = (data: LineChartData) => {
    return (
        <LineChart
            width={730}
            height={250}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="accuracy" stroke="#8884d8" />
            <Line type="monotone" dataKey="loss" stroke="#82ca9d" />
        </LineChart>
    )
}
