import { useEffect, useState } from 'react'
import {
    SampleLineChart,
    TrainingLineChart,
    transformPayloadToLineChartData,
} from '../../components/Chart/LineChart'
import { MorphicInstance } from '../../config'
import { SessionsModel } from '../../generated'

export const TrainingsTable = () => {
    const [trainings, setTrainings] = useState<SessionsModel[]>([])
    const refresh = () => {
        MorphicInstance.default.listSessions().then((data) => {
            console.log('fetched all training sessions', data)
            setTrainings(data)
        })
    }

    useEffect(() => {
        refresh()
    }, [])

    const renderTrainingChart = () => {
        if (trainings[0] && trainings[0].metrics) {
            console.log(
                'trainings in progress, showing programmatic training chart'
            )
            const transformedData = transformPayloadToLineChartData(
                JSON.parse(trainings[0].metrics)
            )
            return TrainingLineChart(transformedData)
        }
        return SampleLineChart()
    }

    return (
        <div className="container max-w-screen-lg px-4 mx-auto sm:px-8">
            <div className="py-8">
                <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                    <h1 className="mb-2 text-2xl font-semibold">
                        Monitor a training
                    </h1>
                    {renderTrainingChart()}
                </div>
            </div>
        </div>
    )
}
