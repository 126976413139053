import { Dialog, Transition } from '@headlessui/react'
import { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MorphicInstance } from '../../config'
import { DatasetModel } from '../../generated'
import Empty from '../../illustrations/empty.svg'
import toast from 'react-hot-toast'
import { capitalize } from '../../utils/strings'
import { formatDate } from '../../utils/dates'

export const DatasetsTable = ({ onlyOwned }: { onlyOwned: boolean }) => {
    const [isOpen, setIsOpen] = useState(false)

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [shape, setShape] = useState('')
    const [datasets, setDatasets] = useState<DatasetModel[]>([])

    const refresh = useCallback(() => {
        if (onlyOwned) {
            MorphicInstance.default.listMyDatasets().then(setDatasets)
        } else {
            MorphicInstance.default.listAllDatasets().then(setDatasets)
        }
    }, [onlyOwned])

    const handleSubmit = () => {
        MorphicInstance.default
            .createDataset({
                name,
                description,
                published: false,
                shape,
            })
            .then(() => {
                setIsOpen(false)
                refresh()
                toast.success('Dataset created!')
            })
            .catch((err) => {
                toast.error(err.message)
            })
    }

    useEffect(() => {
        refresh()
    }, [refresh])

    return (
        <>
            <Transition
                show={isOpen}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Dialog
                    onClose={() => setIsOpen(false)}
                    className="fixed inset-0 z-10 overflow-y-auto"
                >
                    <div className="flex items-center justify-center min-h-screen">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                        <div className="relative max-w-screen-md p-8 mx-auto bg-white rounded-xl">
                            <Dialog.Title className="text-lg">
                                Create a dataset
                            </Dialog.Title>

                            <div>
                                <input
                                    type="text"
                                    className="px-3 py-1.5 mt-4 border rounded-md w-96"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <div>
                                <textarea
                                    className="px-3 py-1.5 mt-4 border rounded-md w-96"
                                    rows={8}
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                />
                            </div>

                            <div>
                                <input
                                    type="text"
                                    className="px-3 py-1.5 mt-4 border rounded-md w-96"
                                    placeholder="Shape"
                                    value={shape}
                                    onChange={(e) => setShape(e.target.value)}
                                />
                            </div>

                            <button
                                onClick={handleSubmit}
                                className="px-4 py-2 mx-auto mt-8 mb-4 text-sm font-semibold text-white bg-blue-500 rounded-sm shadow-sm"
                            >
                                Create dataset
                            </button>

                            {/* ... */}
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <div className="container max-w-screen-lg px-4 mx-auto sm:px-8">
                <div className="py-8">
                    <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                        <h1 className="mb-2 text-2xl font-semibold">
                            {onlyOwned ? 'My Datasets' : 'Published Datasets'}
                        </h1>
                        <p className="mb-8 text-sm text-slate-500">
                            Datasets that are ready for training.
                        </p>
                        <button
                            onClick={() => {
                                setIsOpen(true)
                                setName('')
                                setDescription('')
                                setShape('')
                            }}
                            className="px-4 py-2 mx-auto mb-4 text-sm font-semibold text-white bg-blue-500 rounded-sm shadow-sm"
                        >
                            Create a dataset
                        </button>
                        <div className="inline-block min-w-full overflow-hidden border rounded-lg">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <td className="px-5 py-5 bg-gray-100">
                                            Name
                                        </td>
                                        <td className="px-5 py-5 bg-gray-100">
                                            Description
                                        </td>
                                        <td className="px-5 py-5 bg-gray-100">
                                            Owner
                                        </td>
                                        <td className="px-5 py-5 bg-gray-100">
                                            Created
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datasets.length > 0 ? (
                                        datasets.map((dataset) => (
                                            <tr>
                                                <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                                    <div className="flex items-center">
                                                        <NavLink
                                                            to={`/app/dataset/${dataset.id}`}
                                                            className="text-blue-500 underline whitespace-no-wrap"
                                                        >
                                                            {dataset.name}
                                                        </NavLink>
                                                    </div>
                                                </td>
                                                <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                                    <p className="text-gray-600 whitespace-no-wrap">
                                                        {dataset.description}
                                                    </p>
                                                </td>
                                                <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {dataset.owner.name}
                                                    </p>
                                                </td>
                                                <td className="px-5 py-5 text-sm whitespace-no-wrap bg-white border-b border-gray-200">
                                                    {formatDate(
                                                        dataset.created
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={4}>
                                                <div className="flex flex-col items-center justify-center w-full py-24">
                                                    <img
                                                        className="w-1/4"
                                                        src={Empty}
                                                    />
                                                    <div className="mt-12 text-lg">
                                                        No datasets found!
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {/* <div className="flex flex-col items-center px-5 py-5 bg-white xs:flex-row xs:justify-between">
                                <div className="flex items-center">
                                    <button
                                        type="button"
                                        className="w-full p-4 text-base text-gray-600 bg-white border rounded-l-xl hover:bg-gray-100"
                                    >
                                        <svg
                                            width="9"
                                            fill="currentColor"
                                            height="8"
                                            className=""
                                            viewBox="0 0 1792 1792"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"></path>
                                        </svg>
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full px-4 py-2 text-base text-indigo-500 bg-white border-t border-b hover:bg-gray-100 "
                                    >
                                        1
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100"
                                    >
                                        2
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full px-4 py-2 text-base text-gray-600 bg-white border-t border-b hover:bg-gray-100"
                                    >
                                        3
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100"
                                    >
                                        4
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full p-4 text-base text-gray-600 bg-white border-t border-b border-r rounded-r-xl hover:bg-gray-100"
                                    >
                                        <svg
                                            width="9"
                                            fill="currentColor"
                                            height="8"
                                            className=""
                                            viewBox="0 0 1792 1792"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
