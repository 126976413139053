import { Login } from './pages/Login'
import {
    Route,
    BrowserRouter,
    Switch,
    Redirect,
    RouteProps,
} from 'react-router-dom'
import { Home } from './pages/Home'
import { Dashboard } from './pages/Dashboard'
import { Signup } from './pages/Signup'
import { NotFound } from './pages/NotFound'
import cookies from 'js-cookie'

const ProtectedRoute = ({ ...routeProps }: RouteProps) => {
    const isAuthenticated = cookies.get('api_key')
    const authenticationPath = '/login'

    if (isAuthenticated) {
        return <Route {...routeProps} />
    } else {
        return <Redirect to={{ pathname: authenticationPath }} />
    }
}

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <ProtectedRoute path="/app" component={Dashboard} />
                <Route path="/" component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}

export default App
