/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_create_project_projects_create_post } from '../models/Body_create_project_projects_create_post';
import type { DatasetModel } from '../models/DatasetModel';
import type { ExistingUser } from '../models/ExistingUser';
import type { NewDatasetModel } from '../models/NewDatasetModel';
import type { NewSessionsModel } from '../models/NewSessionsModel';
import type { NewUser } from '../models/NewUser';
import type { ProjectModel } from '../models/ProjectModel';
import type { ProjectWithEligibleDatasets } from '../models/ProjectWithEligibleDatasets';
import type { SessionsDatasetModel } from '../models/SessionsDatasetModel';
import type { SessionsModel } from '../models/SessionsModel';
import type { UpdateDatasetModel } from '../models/UpdateDatasetModel';
import type { UserModel } from '../models/UserModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Redirect To Docs
     * Redirect to the documentation.
     * @returns any Successful Response
     * @throws ApiError
     */
    public redirectToDocs(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/',
        });
    }

    /**
     * Ping
     * Ping the server.
     * @returns any Successful Response
     * @throws ApiError
     */
    public ping(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ping',
        });
    }

    /**
     * Create User
     * Create a new user.
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public createUser(
        requestBody: NewUser,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/signup',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login User
     * Login a user.
     * @param requestBody
     * @returns UserModel Successful Response
     * @throws ApiError
     */
    public loginUser(
        requestBody: ExistingUser,
    ): CancelablePromise<UserModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Logout User
     * Logout a user.
     * @returns any Successful Response
     * @throws ApiError
     */
    public logoutUser(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/logout',
        });
    }

    /**
     * Me User
     * Get the current user.
     * @returns UserModel Successful Response
     * @throws ApiError
     */
    public meUser(): CancelablePromise<UserModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/me',
        });
    }

    /**
     * List All Datasets
     * Get the user's owned datasets.
     * @returns DatasetModel Successful Response
     * @throws ApiError
     */
    public listAllDatasets(): CancelablePromise<Array<DatasetModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/datasets/all',
        });
    }

    /**
     * List My Datasets
     * Get the user's owned projects.
     * @returns DatasetModel Successful Response
     * @throws ApiError
     */
    public listMyDatasets(): CancelablePromise<Array<DatasetModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/datasets/owned',
        });
    }

    /**
     * Create Dataset
     * Create a new dataset.
     * @param requestBody
     * @returns DatasetModel Successful Response
     * @throws ApiError
     */
    public createDataset(
        requestBody: NewDatasetModel,
    ): CancelablePromise<DatasetModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/datasets/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dataset Details
     * Get details of a dataset
     * @param datasetId
     * @returns DatasetModel Successful Response
     * @throws ApiError
     */
    public getDatasetDetails(
        datasetId: string,
    ): CancelablePromise<DatasetModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/datasets/details',
            query: {
                'dataset_id': datasetId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Dataset
     * Update a dataset.
     * @param requestBody
     * @returns DatasetModel Successful Response
     * @throws ApiError
     */
    public updateDataset(
        requestBody: UpdateDatasetModel,
    ): CancelablePromise<DatasetModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/datasets/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Dataset
     * Delete a dataset.
     * @param datasetId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteDataset(
        datasetId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/datasets/delete',
            query: {
                'dataset_id': datasetId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List All Projects
     * Get all projects.
     * @returns ProjectModel Successful Response
     * @throws ApiError
     */
    public listAllProjects(): CancelablePromise<Array<ProjectModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/projects/all',
        });
    }

    /**
     * List My Projects
     * Get the user's owned projects.
     * @returns ProjectModel Successful Response
     * @throws ApiError
     */
    public listMyProjects(): CancelablePromise<Array<ProjectModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/projects/owned',
        });
    }

    /**
     * Create Project
     * Add a project
     * @param formData
     * @returns ProjectModel Successful Response
     * @throws ApiError
     */
    public createProject(
        formData: Body_create_project_projects_create_post,
    ): CancelablePromise<ProjectModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/projects/create',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Project Info
     * Get a project info and a list of datasets with a matching shape.
     * @param projectId
     * @returns ProjectWithEligibleDatasets Successful Response
     * @throws ApiError
     */
    public projectInfo(
        projectId: number,
    ): CancelablePromise<ProjectWithEligibleDatasets> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/projects/{project_id}/info',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Models
     * Download the dataset models
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public downloadModels(
        projectId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/projects/download_model',
            query: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Sessions
     * Get the user's owned sessions.
     * @returns SessionsModel Successful Response
     * @throws ApiError
     */
    public listSessions(): CancelablePromise<Array<SessionsModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sessions/owned',
        });
    }

    /**
     * Create Session
     * Create a new Session.
     * @param requestBody
     * @returns SessionsModel Successful Response
     * @throws ApiError
     */
    public createSession(
        requestBody: NewSessionsModel,
    ): CancelablePromise<SessionsModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sessions/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Session
     * Delete a session.
     * @param sessionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteSession(
        sessionId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sessions/delete',
            query: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Dataset
     * Update a dataset.
     * @param requestBody
     * @returns SessionsModel Successful Response
     * @throws ApiError
     */
    public updateDataset1(
        requestBody: SessionsModel,
    ): CancelablePromise<SessionsModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sessions/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Participate Sessions
     * Get the sessions a dataset host should participate in.
     * @returns SessionsDatasetModel Successful Response
     * @throws ApiError
     */
    public participateSessions(): CancelablePromise<Array<SessionsDatasetModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sessions/should_participate',
        });
    }

}