import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MorphicInstance } from '../../config'
import { DatasetModel } from '../../generated'
import { formatDate } from '../../utils/dates'

export const Dataset = () => {
    const { datasetId } = useParams<{ datasetId: string }>()

    const [dataset, setDataset] = useState<DatasetModel>()

    useEffect(() => {
        MorphicInstance.default.getDatasetDetails(datasetId).then(setDataset)
    }, [datasetId])

    if (!dataset) {
        return <div>Loading...</div>
    }

    return (
        <>
            <section className="w-full py-8">
                <div className="max-w-screen-lg mx-auto">
                    <h3 className="text-sm text-gray-400">Dataset</h3>
                    <h1 className="text-4xl font-semibold">{dataset.name}</h1>
                    <h2 className="mt-2 text-gray-600">
                        {dataset.description}
                    </h2>
                    <div className="mt-4 text-gray-300">
                        <span className="font-semibold text-black">
                            {dataset.owner.name}{' '}
                        </span>
                        {' • '}
                        <span className="font-semibold text-black">
                            {formatDate(dataset.created)}
                        </span>
                    </div>
                </div>
            </section>

            <section className="border-b ">
                <div className="flex max-w-screen-lg mx-auto">
                    <div className="py-1 mr-8 border-b-2 border-black">
                        Overview
                    </div>
                    {/* <div className="py-1 mr-8">Schema</div> */}
                    {/* <div className="py-1 mr-8">Version history</div> */}
                </div>
            </section>
            <section className="mt-12">
                {/* <div className="flex max-w-screen-lg mx-auto">
                    <div className="w-2/3 text-slate-600">
                        {dataset.about}
                    </div>
                    <div className="w-1/3">
                        <div className="flex flex-col pl-12">
                            <div>
                                <h1 className="text-lg font-bold">Size</h1>
                                <h2>{dataset.size}</h2>
                            </div>
                            <div className="mt-8">
                                <h1 className="text-lg font-bold">Samples</h1>
                                <h2>{dataset.samples}</h2>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
        </>
    )
}
