// import axios from 'axios'
import { MorphicClient } from './generated/MorphicClient'
export const SERVER_URL = '/api'

// export const ServerInstance = axios.create({
//     baseURL: SERVER_URL,
// })

export const MorphicInstance = new MorphicClient({
    BASE: SERVER_URL,
    CREDENTIALS: 'include',
    WITH_CREDENTIALS: true,
})
