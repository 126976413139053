export const NotFound = () => {
    return (
        <div className="flex items-center justify-center w-screen h-screen">
            <div className="text-center">
                <h1 className="text-lg font-semibold">404</h1>
                <p>Page not found</p>
            </div>
        </div>
    )
}
