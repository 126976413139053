import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { capitalize } from './strings'

dayjs.extend(relativeTime)
dayjs.extend(utc)

export const formatDate = (date: string) =>
    capitalize(dayjs.utc(date).fromNow())
